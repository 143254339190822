import { SITE_CONFIG } from '@config/siteConfig';
import phonesMetadata from '@domains/ad/generatedData/phonesMetadata.json';
import { checkIsDesktop } from '@domains/shared/helpers/checkIsDesktop';
import type { CountryCode } from 'libphonenumber-js/core';
import { parsePhoneNumber } from 'libphonenumber-js/core';
import { useEffect, useMemo, useState } from 'react';

const { phoneNumbers } = SITE_CONFIG;

interface UsePhoneNumberArguments {
    number: string;
    visible?: boolean;
    nationalCountryCode?: string;
    country?: string;
    onRevealCallback?: () => void;
}

interface UsePhoneNumberResult {
    firstThreeDigits: string;
    phoneNumber: string;
    shouldRevealNumber: boolean;
    isClickable: boolean;
    revealNumber: () => void;
}

export const usePhoneNumber = ({
    number,
    onRevealCallback,
    visible = false,
    country = phoneNumbers.country,
    nationalCountryCode = phoneNumbers.countryCode,
}: UsePhoneNumberArguments): UsePhoneNumberResult => {
    const [shouldRevealNumber, setShouldRevealNumber] = useState(visible);
    const [isClickable, setIsClickable] = useState(false);

    const revealNumber = (): void => {
        setShouldRevealNumber(true);
        onRevealCallback?.();
    };

    useEffect(() => {
        setIsClickable(checkIsDesktop());
    }, []);

    const { phoneNumber, firstThreeDigits } = useMemo(() => {
        /**
         * Some phone numbers in the database are broken and invalid. We still want to display those.
         * Invalid numbers will not be parsed properly. In such case We've to display raw number.
         */
        let phoneNumber, firstThreeDigits;
        try {
            // Happy-path: phone number was normalized and provided in E.164 format
            phoneNumber = parsePhoneNumber(number, country as CountryCode, phonesMetadata as never); // Parse number
            const isNational = phoneNumber?.countryCallingCode === nationalCountryCode; // Check if it's national

            firstThreeDigits = phoneNumber?.nationalNumber.slice(0, 3); // Get three first digits
            phoneNumber = isNational ? phoneNumber?.formatNational() : phoneNumber?.formatInternational(); // Assign correct number to display
        } catch {
            // Path if there was an error with parsing number so it's probably unnormalized / legacy.
            firstThreeDigits = number.slice(0, 3);
            phoneNumber = number;
        }

        return {
            phoneNumber,
            firstThreeDigits,
        };
    }, [number, country, nationalCountryCode]);

    return {
        firstThreeDigits,
        phoneNumber,
        shouldRevealNumber,
        revealNumber,
        isClickable,
    };
};
