import type { BulletVariantValue } from '../types/bullet';
import { decreaseVariants } from './decreaseVariants';
import { increaseVariants } from './increaseVariants';

export const getBulletsAfterSlidingNext = (
    bullets: BulletVariantValue[],
    firstVisibleStandardBulletIndex: number,
    currentIndex: number,
): BulletVariantValue[] => {
    const indexesToIncrease = [firstVisibleStandardBulletIndex, firstVisibleStandardBulletIndex - 1];
    const indexesToDecrease = [currentIndex, currentIndex + 1];

    const bulletsAfterIncrease = increaseVariants(bullets, indexesToIncrease);
    const finalBullets = decreaseVariants(bulletsAfterIncrease, indexesToDecrease);

    return finalBullets;
};
