import { BULLET_VARIANT } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';

export const getBulletVariant = ({
    variant,
    isActive,
    isBulletsScrollingEnabled,
}: {
    variant: BulletVariantValue;
    isActive: boolean;
    isBulletsScrollingEnabled: boolean;
}): BulletVariantValue => {
    if (isActive) {
        return BULLET_VARIANT.active;
    }

    return isBulletsScrollingEnabled ? variant : BULLET_VARIANT.standard;
};
