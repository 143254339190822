import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    position: relative;
    margin-bottom: 8px;
    font-size: ${SIZE.p4};
`;

export const CallToActionTextWrapper = styled.span`
    color: ${({ theme }): string => theme.deprecated.domains.companies.gdprNote.callToAction.color};
    font-weight: ${WEIGHT.semibold};
    text-decoration: underline;
`;

export const DefaultDetailsTrigger = styled.button`
    padding: 0;
    border: 0;
    background: none;
    color: ${({ theme }): string => theme.deprecated.domains.companies.gdprNote.trigger.color};
    font-size: inherit;
    text-align: left;
    cursor: pointer;
`;

export const PopoverDetailsTrigger = styled(DefaultDetailsTrigger)`
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -16px;
        width: 0;
        height: 0;
        transform: translateY(-50%);
        border-width: 6px 0 6px 7px;
        border-style: solid;
        border-color: transparent transparent transparent
            ${({ theme }): string => theme.deprecated.domains.companies.gdprNote.popover.backgroundColor};
        pointer-events: none;
    }
`;

export const DefaultDetailsContent = styled.div`
    margin-top: 16px;

    p {
        margin-top: 0;
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: inherit;
    }
`;

export const PopoverDetailsContent = styled(DefaultDetailsContent)`
    position: absolute;
    z-index: ${LAYER.x1};
    top: 50%;
    right: calc(100% + 16px);
    width: 100vw;
    max-width: 320px;
    margin: 0;
    padding: 12px;
    transform: translateY(-50%);
    background-color: ${({ theme }): string => theme.deprecated.domains.companies.gdprNote.popover.backgroundColor};
    color: ${({ theme }): string => theme.deprecated.domains.companies.gdprNote.popover.color};
`;
