import type { InputVariant } from '@domains/shared/types/inputVariant';
import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import { TextAreaElement } from './TextArea.theme';

interface Props extends ComponentProps<'textarea'> {
    variant?: InputVariant;
}

const TextAreaInput: ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (
    { variant = 'default', rows = 5, ...htmlProps },
    ref,
) => {
    return (
        <TextAreaElement ref={ref} rows={rows} variant={variant} aria-invalid={variant === 'invalid'} {...htmlProps} />
    );
};

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(TextAreaInput);
