import type { JSX } from 'react';

import { BULLET_TEST_ID, BULLET_VARIANT } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';
import { ActiveBullet, MediumBullet, SmallBullet, StandardBullet } from './Bullet.theme';

interface Props {
    variant: BulletVariantValue;
}

const COMPONENT = {
    [BULLET_VARIANT.active]: {
        testId: BULLET_TEST_ID.active,
        BulletComponent: ActiveBullet,
    },
    [BULLET_VARIANT.standard]: {
        testId: BULLET_TEST_ID.standard,
        BulletComponent: StandardBullet,
    },
    [BULLET_VARIANT.medium]: {
        testId: BULLET_TEST_ID.medium,
        BulletComponent: MediumBullet,
    },
    [BULLET_VARIANT.small]: {
        testId: BULLET_TEST_ID.small,
        BulletComponent: SmallBullet,
    },
};

export const Bullet = ({ variant }: Props): JSX.Element => {
    const { BulletComponent, testId } = COMPONENT[variant];

    return <BulletComponent data-testid={testId} />;
};
