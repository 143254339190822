import { SITE_CONFIG } from '@config/siteConfig';

const PHONE_NUMBER_PATTERN = '\\d{7,}';
const PHONE_NUMBER_REPLACE_PATTERN = /\D/g; // replace everything '+' as the first char and otherwise any digit
const FORMAT_PHONE_NUMBER_REGEX = /(\d{1,3})/g;

// Country code regex pattern based on https://en.wikipedia.org/wiki/List_of_country_calling_codes
export const COUNTRY_CODE_PATTERN_WITHOUT_SIGN =
    '(?:1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\\d)';

export const VALID_COUNTRY_CODE_REGEX = new RegExp(`^\\+${COUNTRY_CODE_PATTERN_WITHOUT_SIGN}$`);

export const VALID_PHONE_NUMBER_REGEX = new RegExp(`^${PHONE_NUMBER_PATTERN}$`);

const FULL_PHONE_NUMBER_WITH_OPTIONAL_PHONE_REGEX = new RegExp(
    `^(?:\\+|00)(${COUNTRY_CODE_PATTERN_WITHOUT_SIGN}) ?(${PHONE_NUMBER_PATTERN})?$`,
);

// Remove everything except `+` at the beginning and digits
export const removeForbiddenChars = (value: string): string => {
    if (value.startsWith('+')) {
        return `+${value.slice(1).replace(PHONE_NUMBER_REPLACE_PATTERN, '')}`;
    }

    return value.replace(PHONE_NUMBER_REPLACE_PATTERN, '');
};

// Format phone number
export const formatPhoneNumber = (value: string): string => {
    if (!value) {
        return '';
    }
    let plus = '';
    let valueWithoutPlus = value;

    if (valueWithoutPlus.startsWith('+')) {
        plus = '+';
        valueWithoutPlus = value.slice(1);
    }

    return `${plus}${valueWithoutPlus
        .split(FORMAT_PHONE_NUMBER_REGEX)
        .filter((value) => !!value)
        .join(' ')}`;
};

// Parse values, it handles formats (with any char as the number separator) like:
// * +48 100 100 100
// * 0048 100 100 100
// * 0048 100 100 100
export const parsePhoneNumberWithCountryCode = (
    value: string,
    skipCountryCode: boolean,
): { countryCode: string | undefined; phoneNumber: string } => {
    if (!value) {
        return { countryCode: `+${SITE_CONFIG.phoneNumbers.countryCode}`, phoneNumber: '' };
    }
    const filteredOutDefaultValue = removeForbiddenChars(value);
    const match = filteredOutDefaultValue.match(FULL_PHONE_NUMBER_WITH_OPTIONAL_PHONE_REGEX);
    let countryCode = skipCountryCode ? undefined : '';
    let phoneNumber = filteredOutDefaultValue;

    if (!skipCountryCode && match && match.length > 0) {
        // Use country code only when it hasn't been pre-filled
        countryCode = `+${match[1]}`;
    }
    if (match && match.length === 3) {
        phoneNumber = match[2];
    }

    return { countryCode, phoneNumber };
};
