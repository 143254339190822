import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import type { JSX } from 'react';
import { Fragment } from 'react';

const generateLink = (text: string, key: string, link: string): JSX.Element => {
    return (
        <p>
            {text.split(key).map((textFragment, index) => {
                if (index % 2 === 0) {
                    return <Fragment key={index}>{textFragment}</Fragment>;
                }

                return (
                    <a href={link} target="_blank" rel="noreferrer" key={index}>
                        {textFragment}
                    </a>
                );
            })}
        </p>
    );
};

export const transformGdprDetails = ({
    description,
    privacyPolicyLink,
    termsAndConditionsLink,
}: {
    description: string;
    privacyPolicyLink: string;
    termsAndConditionsLink?: string;
}): string | JSX.Element[] => {
    return replacePlaceholders(description, (text) => {
        if (text.includes('%heading%')) {
            return (
                <p>
                    {text.split('%heading%').map((textFragment, index) => {
                        if (index === 1) {
                            return (
                                <Fragment key={index}>
                                    <strong>{textFragment}</strong>
                                    <br />
                                </Fragment>
                            );
                        }

                        return textFragment.trim();
                    })}
                </p>
            );
        }
        if (text.includes('%link%')) {
            return generateLink(text, '%link%', privacyPolicyLink);
        }
        if (text.includes('%termsAndConditionsLink%') && termsAndConditionsLink) {
            return generateLink(text, '%termsAndConditionsLink%', termsAndConditionsLink);
        }

        return <p>{text}</p>;
    });
};
