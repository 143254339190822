import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import styled from '@emotion/styled';
import { WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
`;

export const ShowNumberTextButton = styled(TextButton)`
    padding: 0 8px;
    text-transform: lowercase;
`;

export const BasePhoneNumber = styled.span`
    transform: none;
    font-weight: ${WEIGHT.semibold};
`;

export const FullPhoneNumberLink = styled(BasePhoneNumber)`
    color: ${({ theme }): string => theme.deprecated.domains.ad.phoneNumber.fullPhoneNumber.color};
    text-decoration: none;
`.withComponent('a');
