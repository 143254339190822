import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ComponentProps, JSX } from 'react';
import { useContext, useRef, useState } from 'react';

import {
    CallToActionTextWrapper,
    Container,
    DefaultDetailsContent,
    DefaultDetailsTrigger,
    PopoverDetailsContent,
    PopoverDetailsTrigger,
} from './GdprNote.theme';

interface Props extends ComponentProps<typeof Container> {
    intro: string;
    details: string | JSX.Element[];
    shouldUsePopover?: boolean;
}

export const GdprNote = ({
    intro,
    details,
    shouldUsePopover: initialShouldUsePopover,
    ...props
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const containerRef = useRef(null);
    const { isDesktop } = useContext(RWDContext);
    const [shouldShowDetails, setShouldShowDetails] = useState(false);

    const shouldUsePopover = initialShouldUsePopover ?? !!isDesktop;
    const shouldShowPopoverArrow = shouldUsePopover && shouldShowDetails;

    const DetailsTrigger = shouldShowPopoverArrow ? PopoverDetailsTrigger : DefaultDetailsTrigger;
    const DetailsContent = shouldUsePopover ? PopoverDetailsContent : DefaultDetailsContent;

    const handleTriggerClick = (): void => {
        setShouldShowDetails((currentValue) => !currentValue);
    };

    useClickOutside(containerRef, () => {
        if (shouldUsePopover && shouldShowDetails) {
            setShouldShowDetails(false);
        }
    });

    return (
        <Container ref={containerRef} {...props}>
            <DetailsTrigger data-cy="see-gdpr-note-button" type="button" onClick={handleTriggerClick}>
                {intro}&nbsp;
                <CallToActionTextWrapper>
                    {shouldShowDetails
                        ? t('frontend.global.contact-form.gdpr-less')
                        : t('frontend.global.contact-form.gdpr-more')}
                </CallToActionTextWrapper>
            </DetailsTrigger>
            {shouldShowDetails ? <DetailsContent role="alert">{details}</DetailsContent> : null}
        </Container>
    );
};
