import { BULLET_VARIANT, NUMBER_OF_VISIBLE_STANDARD_BULLETS } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';

export const getBulletsWithFirstActive = (slidesCount: number): BulletVariantValue[] => {
    // eslint-disable-next-line unicorn/no-new-array -- used new fo create n-length array and fill it easily
    return new Array(slidesCount)
        .fill(BULLET_VARIANT.standard, 0, NUMBER_OF_VISIBLE_STANDARD_BULLETS)
        .fill(BULLET_VARIANT.medium, NUMBER_OF_VISIBLE_STANDARD_BULLETS, NUMBER_OF_VISIBLE_STANDARD_BULLETS + 1)
        .fill(BULLET_VARIANT.small, NUMBER_OF_VISIBLE_STANDARD_BULLETS + 1, slidesCount);
};
