import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import type { CSSProperties } from 'react';

export const BulletsContainer = styled.div<{ width: number }>`
    position: absolute;
    z-index: ${LAYER.x0};
    bottom: 16px;
    left: 50%;
    width: ${({ width }): CSSProperties['width'] => `${width}px`};
    overflow: hidden;
    transform: translateX(-50%);
    transition: width 0.2s ease;
`;

export const Wrapper = styled.div<{ translateX: number }>`
    display: flex;
    align-items: center;
    transform: ${({ translateX }): CSSProperties['transform'] => `translatex(${translateX}px) `};
    transition: transform 0.2s ease;
`;

export const BulletWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 16px;
`;
