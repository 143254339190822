import { Input } from '@domains/shared/components/Input/Input';
import styled from '@emotion/styled';

const COUNTRY_CODE_WIDTH = 60;
const SEPARATOR_HEIGHT = 14;

export const Wrapper = styled.div`
    position: relative;
`;

// ([input height] 40 - [separator height] 14)/2
export const Separator = styled.div`
    position: absolute;
    top: 13px;
    left: ${COUNTRY_CODE_WIDTH}px;
    height: ${SEPARATOR_HEIGHT}px;
    border-left: 1px solid ${({ theme }): string => theme.deprecated.domains.shared.input.append.before.borderColor};
`;

export const CountryCodeInput = styled(Input)`
    position: absolute;
    width: ${COUNTRY_CODE_WIDTH}px;
    border-color: transparent;
    background: none;
    text-align: right;

    &:focus,
    &:hover {
        border-color: transparent;
        background: none;
        box-shadow: none;
    }

    &:disabled {
        &,
        &:hover {
            border-color: transparent;
            background: none;
        }
    }
`;

export const PhoneNumberInput = styled(Input)`
    padding-left: ${COUNTRY_CODE_WIDTH + 10}px;

    ${CountryCodeInput}:focus ~ & {
        box-shadow: ${({ theme }): string => theme.deprecated.domains.shared.input.focus.boxShadow};
    }

    ${CountryCodeInput}:hover ~ & {
        border-color: ${({ theme }): string => theme.deprecated.domains.shared.input.hover.borderColor};
    }
`;
