import { BULLET_VARIANT, NUMBER_OF_VISIBLE_STANDARD_BULLETS } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';

export const getBulletsWithLastActive = (slidesCount: number): BulletVariantValue[] => {
    // eslint-disable-next-line unicorn/no-new-array -- used new fo create n-length array and fill it easily
    return new Array(slidesCount)
        .fill(BULLET_VARIANT.small, 0, slidesCount - NUMBER_OF_VISIBLE_STANDARD_BULLETS - 1)
        .fill(
            BULLET_VARIANT.medium,
            slidesCount - NUMBER_OF_VISIBLE_STANDARD_BULLETS - 1,
            slidesCount - NUMBER_OF_VISIBLE_STANDARD_BULLETS,
        )
        .fill(BULLET_VARIANT.standard, slidesCount - NUMBER_OF_VISIBLE_STANDARD_BULLETS, slidesCount);
};
