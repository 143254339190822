import { NUMBER_OF_VISIBLE_STANDARD_BULLETS } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';
import { decreaseVariants } from './decreaseVariants';
import { increaseVariants } from './increaseVariants';

export const getBulletsAfterSlidingPrevious = (
    bullets: BulletVariantValue[],
    currentIndex: number,
): BulletVariantValue[] => {
    const indexesToDecrease = [currentIndex, currentIndex - 1];
    const indexesToIncrease = [
        currentIndex + NUMBER_OF_VISIBLE_STANDARD_BULLETS,
        currentIndex + NUMBER_OF_VISIBLE_STANDARD_BULLETS + 1,
    ];

    const bulletsAfterIncrease = increaseVariants(bullets, indexesToIncrease);
    const finalBullets = decreaseVariants(bulletsAfterIncrease, indexesToDecrease);

    return finalBullets;
};
