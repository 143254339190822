import type { InputVariant } from '@domains/shared/types/inputVariant';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import type { Theme } from '@lib/styles/themes/type';

interface InputProps {
    variant: InputVariant;
}

const getVariantValue = (variant: InputVariant, property: 'borderColor' | 'backgroundColor', theme: Theme): string => {
    const { input } = theme.deprecated.domains.shared;

    switch (variant) {
        case 'invalid': {
            return input.invalid[property];
        }
        case 'success': {
            return input.success[property];
        }
    }

    return input.default[property];
};

export const DefaultInput = styled.input<InputProps>`
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    transition: box-shadow 0.2s ease-in-out;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    border-color: ${({ variant, theme }): string => getVariantValue(variant, 'borderColor', theme)};
    background-color: ${({ variant, theme }): string => getVariantValue(variant, 'backgroundColor', theme)};
    font-size: ${SIZE.p2};
    font-weight: ${WEIGHT.regular};
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: ${({ theme }): string => theme.deprecated.domains.shared.input.placeholder.color};
    }

    &:focus {
        outline: none;
        box-shadow: ${({ theme }): string => theme.deprecated.domains.shared.input.focus.boxShadow};
    }

    &:hover {
        border-color: ${({ theme }): string => theme.deprecated.domains.shared.input.hover.borderColor};
    }

    &:disabled {
        border-color: ${({ theme }): string => theme.deprecated.domains.shared.input.disabled.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.domains.shared.input.disabled.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.domains.shared.input.disabled.color};
        font-style: normal;
        font-weight: ${WEIGHT.regular};
        cursor: not-allowed;

        &:hover {
            border-color: ${({ theme }): string => theme.deprecated.domains.shared.input.default.borderColor};
        }
    }

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p3};
    }
`;

export const ClearButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    margin: 0;
    padding: 0 12px 0 0;
    border: 0;
    outline: 0;
    background: none;
    color: ${({ theme }): string => theme.deprecated.domains.shared.input.clearButton.color};
    line-height: 1;
    text-align: right;
    cursor: pointer;
`;

export const InputGroupAppend = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    height: 100%;
    padding: 0 12px 0 0;
    color: ${({ theme }): string => theme.deprecated.domains.shared.input.append.color};
    font-size: ${SIZE.p3};
    pointer-events: none;
    &::before {
        content: ' ';
        display: block;
        width: 12px;
        height: 40%;
        border-left: 1px solid ${({ theme }): string => theme.deprecated.domains.shared.input.append.before.borderColor};
    }
`;

interface InputGroupProps {
    variant: 'append' | 'clear';
}

export const InputGroup = styled.div<InputGroupProps>`
    position: relative;

    ${DefaultInput} {
        padding-right: ${({ variant }): number => (variant === 'clear' ? 35 : 45)}px;
    }
`;
