import { makePatternForbiddenCharacters } from '@domains/shared/helpers/makePatternForbiddenCharacters';

// Regex comes from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation and edited slightly as the original passes test@test format.
export const EMAIL_REGULAR_EXPRESSION =
    /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)+$/;

export const SUBDOMAIN_REGULAR_EXPRESSION = /^[\da-z][\da-z-]*$/i;

export const PATTERN_NAME = makePatternForbiddenCharacters('#$%^=[]{}\\~');
export const PATTERN_CITY = makePatternForbiddenCharacters('!#$%^*()_+=<>[]{}\'"\\/`~|.');
export const PATTERN_STREET_AND_NUMBER = makePatternForbiddenCharacters('!#$%^*()_+=<>[]{}\'"\\`~|');

export const WEBSITE_REGULAR_EXPRESSION = /^(https?:\/\/.)[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)$/;
export const NON_WHITESPACE_PATTERN = /^(?!\s+$).+/;

export const REGEX_SAFE_STRING = /[#$()*+,.=?[\\\]^{|}-]/g;
