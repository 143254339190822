import styled from '@emotion/styled';

const BulletBase = styled.div`
    flex-shrink: 0;
    border-style: solid;
    border-radius: 50%;
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.miniGallery.bullet.base.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.miniGallery.bullet.base.backgroundColor};
`;

const StandardBulletBase = styled(BulletBase)`
    width: 8px;
    height: 8px;
`;

export const StandardBullet = styled(StandardBulletBase)`
    border-width: 1px;
`;

export const ActiveBullet = styled(StandardBulletBase)`
    border-width: 3px;
`;

export const MediumBullet = styled(BulletBase)`
    width: 4px;
    height: 4px;
    border-width: 1px;
`;

export const SmallBullet = styled(BulletBase)`
    width: 2px;
    height: 2px;
    border: none;
    background-color: ${({ theme }): string =>
        theme.deprecated.domains.shared.miniGallery.bullet.small.backgroundColor};
`;
