import { usePhoneNumber } from '@domains/shared/hooks/usePhoneNumber/usePhoneNumber';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';

import { BasePhoneNumber, Container, FullPhoneNumberLink, ShowNumberTextButton } from './PhoneNumber.theme';

interface Props {
    number: string;
    nationalCountryCode: string;
    country: string;
    visible?: boolean;
    onRevealCallback?: () => void;
    onPhoneNumberClick?: () => void;
    className?: string;
}

export const PhoneNumber: FC<Props> = ({
    number,
    nationalCountryCode,
    country,
    visible = false,
    onRevealCallback = (): void => undefined,
    onPhoneNumberClick = (): void => undefined,
    className,
    ...htmlProps
}) => {
    const [t] = useTranslations();

    const { firstThreeDigits, shouldRevealNumber, phoneNumber, isClickable, revealNumber } = usePhoneNumber({
        number,
        visible,
        nationalCountryCode,
        country,
        onRevealCallback,
    });

    if (number.length === 0) {
        return null;
    }

    if (shouldRevealNumber) {
        return (
            <Container className={className} {...htmlProps}>
                {isClickable ? (
                    <FullPhoneNumberLink
                        data-cy="phone-number.full-phone-number"
                        href={`tel: ${phoneNumber}`}
                        onClick={onPhoneNumberClick}
                    >
                        {phoneNumber}
                    </FullPhoneNumberLink>
                ) : (
                    <BasePhoneNumber data-cy="phone-number.full-phone-number">{phoneNumber}</BasePhoneNumber>
                )}
            </Container>
        );
    }

    return (
        <Container className={className} {...htmlProps}>
            <BasePhoneNumber>{firstThreeDigits}</BasePhoneNumber>
            <ShowNumberTextButton data-cy="phone-number.show-full-number-button" onClick={revealNumber}>
                {t('frontend.shared.phone-number.reveal-phone-number-button')}
            </ShowNumberTextButton>
        </Container>
    );
};
