import type { InputVariant } from '@domains/shared/types/inputVariant';
import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef, memo } from 'react';

import { DefaultInput, InputGroup, InputGroupAppend } from './Input.theme';

interface Props extends ComponentProps<'input'> {
    append?: string;
    variant?: InputVariant;
    defaultValue?: string;
}

const InputElement: ForwardRefRenderFunction<HTMLInputElement, Props> = (
    { variant = 'default', append, className, hidden, ...other },
    ref,
) => {
    const input = (
        <DefaultInput
            {...other}
            className={className}
            hidden={hidden}
            ref={ref}
            variant={variant}
            aria-invalid={variant === 'invalid'}
        />
    );

    if (!append) {
        return input;
    }

    return (
        <InputGroup className={className} hidden={hidden} variant="append">
            {input}
            <InputGroupAppend>{append}</InputGroupAppend>
        </InputGroup>
    );
};

export const Input = memo(forwardRef<HTMLInputElement, Props>(InputElement));
