import { BULLET_VARIANT } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';

export const decreaseVariants = (bullets: BulletVariantValue[], indexes: number[]): BulletVariantValue[] => {
    const newBullets = [...bullets];

    for (const index of indexes) {
        const currentVariant = newBullets[index];

        if (!currentVariant || currentVariant === BULLET_VARIANT.standard) {
            continue;
        }

        if (currentVariant === BULLET_VARIANT.medium) {
            newBullets[index] = BULLET_VARIANT.standard;
        }

        if (currentVariant === BULLET_VARIANT.small) {
            newBullets[index] = BULLET_VARIANT.medium;
        }
    }

    return newBullets;
};
