import { trickChromeAutocomplete } from '@domains/shared/helpers/trickChromeAutocomplete';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type {
    ChangeEventHandler,
    FocusEventHandler,
    ForwardedRef,
    InputHTMLAttributes,
    JSX,
    SyntheticEvent,
} from 'react';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';

import {
    COUNTRY_CODE_PATTERN_WITHOUT_SIGN,
    formatPhoneNumber,
    parsePhoneNumberWithCountryCode,
    removeForbiddenChars,
    VALID_COUNTRY_CODE_REGEX,
    VALID_PHONE_NUMBER_REGEX,
} from './helpers';
import { CountryCodeInput, PhoneNumberInput, Separator, Wrapper } from './PhoneInput.theme';

export type OnBlurSplitInput = (result: {
    isCountryCodeValid: boolean;
    isPhoneNumberValid: boolean;
    isValid: boolean;
    touchPoint: 'countryCode' | 'phoneNumber';
}) => void;

export type OnFocusSplitInput = (touchPoint: 'countryCode' | 'phoneNumber') => void;

export interface PhoneInputProps
    extends Pick<
        InputHTMLAttributes<HTMLInputElement>,
        'disabled' | 'id' | 'onBlur' | 'onChange' | 'onFocus' | 'placeholder' | 'required'
    > {
    className?: string;
    countryCodeLabelTranslationKey?: string;
    defaultValue?: string | null;
    isCountryCodeEnabled?: boolean;
    isValid?: boolean;
    name: string;
    onBlurSplitInput?: OnBlurSplitInput;
    onFocusSplitInput?: OnFocusSplitInput;
    phoneNumberLabelTranslationKey?: string;
    dataCyPrefix?: string;
}

type ProxyEvent = <T extends SyntheticEvent<HTMLInputElement>>(event: T) => T;

const COUNTRY_CODE_INPUT_NAME = 'ignore-c';

/**
 * This component emulates the single input (it is a wrapper over Input component)
 * but allowing a user to:
 * * enter separately country code and phone number
 * * by default it prefills the country code
 */
const NativePhoneInput = (
    {
        className,
        countryCodeLabelTranslationKey = 'frontend.shared.phone-input.country-code-label',
        defaultValue,
        disabled,
        id,
        isCountryCodeEnabled = false,
        isValid = true,
        name,
        onBlur,
        onBlurSplitInput,
        onChange,
        onFocus,
        onFocusSplitInput,
        phoneNumberLabelTranslationKey,
        placeholder,
        required,
        dataCyPrefix,
    }: PhoneInputProps,
    ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
    const [t] = useTranslations();
    const countryCodeInputRef = useRef<HTMLInputElement | null>(null);
    const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);
    const inputVariant = isValid ? undefined : 'invalid';

    // Get default values and set the default country code
    const { defaultCountryCode, defaultPhoneNumber } = useMemo<{
        defaultCountryCode: string;
        defaultPhoneNumber: string;
    }>(() => {
        const { countryCode: defaultCountryCode, phoneNumber: defaultPhoneNumber } = parsePhoneNumberWithCountryCode(
            defaultValue || '',
            false,
        );

        return {
            defaultCountryCode: defaultCountryCode || '',
            defaultPhoneNumber: formatPhoneNumber(defaultPhoneNumber),
        };
    }, [defaultValue]);

    const parentRef = useMemo(
        () => ({
            focus: (): void => {
                phoneNumberInputRef.current?.focus?.();
            },
            name,
            get value(): string {
                // Do not return country code if the phone number is empty - handle the situation when the field is optional
                // and we as default prefill the country code
                if (!phoneNumberInputRef.current?.value?.length) {
                    return '';
                }

                return `${countryCodeInputRef.current?.value} ${removeForbiddenChars(
                    phoneNumberInputRef.current?.value || '',
                )}`;
            },
            set value(value: string) {
                const { countryCode, phoneNumber } = parsePhoneNumberWithCountryCode(value, false);
                const { current: countryCodeInput } = countryCodeInputRef;
                const { current: phoneNumberInput } = phoneNumberInputRef;

                if (countryCodeInput && phoneNumberInput) {
                    countryCodeInput.value = countryCode || '';
                    phoneNumberInput.value = formatPhoneNumber(phoneNumber);
                }
            },
        }),
        [name],
    );

    // Note: Unfortunately react-hook-form doesn't keep ref reference, so this handler is executed on every re-render
    useImperativeHandle(ref, () => parentRef as HTMLInputElement, [parentRef]);

    // Proxy the event delivered up by replacing the input name and value
    const proxyEvent = useCallback<ProxyEvent>(
        (event) => ({ ...event, target: parentRef, currentTarget: parentRef }),
        [parentRef],
    );

    const handleCountryCodeInput = useMemo<ChangeEventHandler<HTMLInputElement>>(() => {
        const countryCodeRegex = new RegExp(`^\\+${COUNTRY_CODE_PATTERN_WITHOUT_SIGN}$`);

        return (event): void => {
            const { target } = event;
            let newValue = target.value;

            // When value is cleared, set by default '+'
            if (newValue.length === 0 || !newValue.startsWith('+')) {
                newValue = `+${newValue}`;
            }
            // Disallow using any non-phone number chars
            newValue = removeForbiddenChars(newValue);
            target.value = newValue;

            // When it is valid country code, move to the next input
            if (countryCodeRegex.test(newValue)) {
                phoneNumberInputRef.current?.focus?.();
            }

            // Propagate event
            onChange?.(proxyEvent(event));
        };
    }, [onChange, proxyEvent]);

    const handlePhoneNumberInput = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event): void => {
            const { target } = event;
            // Cursor position
            const currentCursorPosition = target.selectionStart;
            const isAfterLastCharCursorPosition = target.selectionStart === target.value.length;
            // Handle value and value from autocomplete (with country code)
            const { countryCode, phoneNumber } = parsePhoneNumberWithCountryCode(target.value, !isCountryCodeEnabled);
            const { current: countryCodeInput } = countryCodeInputRef;

            if (countryCode && countryCodeInput) {
                countryCodeInput.value = countryCode; // Replace country code with the pasted value
            }
            // Format
            const formattedValue = formatPhoneNumber(phoneNumber);

            target.value = formattedValue;
            // Set the cursor position
            const newCursorPosition = isAfterLastCharCursorPosition ? formattedValue.length : currentCursorPosition;

            target.setSelectionRange(newCursorPosition, newCursorPosition);
            // Propagate event
            onChange?.(proxyEvent(event));
        },
        [isCountryCodeEnabled, onChange, proxyEvent],
    );

    const handleBlurAndAndFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
        (event) => {
            const touchPoint = event.target.name === COUNTRY_CODE_INPUT_NAME ? 'countryCode' : 'phoneNumber';

            if (event.type === 'focus') {
                onFocus?.(proxyEvent(event));
                onFocusSplitInput?.(touchPoint);

                return;
            }
            onBlur?.(proxyEvent(event));

            if (!onBlurSplitInput) {
                return;
            }

            // Unfortunately we duplicate validation rules, but we are able to execute
            // callbacks without any delay of awaiting the react-hook-form, especially that we don't know if such validation is attached
            const countryCodeValue = countryCodeInputRef.current?.value || '';
            const isCountryCodeValid =
                countryCodeValue.length > 0 ? VALID_COUNTRY_CODE_REGEX.test(countryCodeValue) : !required;
            const phoneNumberValue = removeForbiddenChars(phoneNumberInputRef.current?.value || '');
            const isPhoneNumberValid =
                phoneNumberValue.length > 0 ? VALID_PHONE_NUMBER_REGEX.test(phoneNumberValue) : !required;

            onBlurSplitInput({
                isCountryCodeValid,
                isPhoneNumberValid,
                isValid: isCountryCodeValid && isPhoneNumberValid,
                touchPoint,
            });
        },
        [onBlur, onBlurSplitInput, onFocus, onFocusSplitInput, proxyEvent, required],
    );

    const dataCyPrefixToUse = dataCyPrefix ? `${dataCyPrefix}.` : '';

    return (
        <Wrapper className={className}>
            <CountryCodeInput
                aria-label={trickChromeAutocomplete(t(countryCodeLabelTranslationKey))}
                autoComplete="off"
                data-cy={`${dataCyPrefixToUse}phone-input.country`}
                defaultValue={defaultCountryCode}
                disabled={!isCountryCodeEnabled}
                inputMode="tel"
                minLength={2}
                maxLength={4}
                name={COUNTRY_CODE_INPUT_NAME}
                onBlur={handleBlurAndAndFocus}
                onFocus={handleBlurAndAndFocus}
                onInput={handleCountryCodeInput}
                ref={countryCodeInputRef}
                type="text"
            />
            <Separator />
            <PhoneNumberInput
                aria-label={phoneNumberLabelTranslationKey && t(phoneNumberLabelTranslationKey)}
                data-cy={`${dataCyPrefixToUse}phone-input.phone`}
                defaultValue={defaultPhoneNumber}
                disabled={disabled}
                id={id}
                inputMode="tel"
                // maxLength (without country code), e.g. "123 456 789 01"
                maxLength={14}
                name={name}
                onBlur={handleBlurAndAndFocus}
                onFocus={handleBlurAndAndFocus}
                onInput={handlePhoneNumberInput}
                placeholder={placeholder}
                ref={phoneNumberInputRef}
                type="tel"
                variant={inputVariant}
            />
        </Wrapper>
    );
};

export const PhoneInput = forwardRef(NativePhoneInput);
