import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import type { ComponentProps, JSX } from 'react';

import { ErrorIcon, ErrorWrapper } from './FieldError.theme';

interface Props extends ComponentProps<typeof ErrorWrapper> {
    error?: string | true;
    dataCy?: string;
}

export const FieldError = ({ error = '', dataCy = 'field-error', ...props }: Props): JSX.Element | null => {
    if (typeof error !== 'string' || error.length === 0) {
        return null;
    }

    return (
        <ErrorWrapper data-cy={dataCy} role="alert" {...props}>
            <ErrorIcon icon={faExclamationTriangle} />
            {error}
        </ErrorWrapper>
    );
};
