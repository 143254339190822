import type { InputVariant } from '@domains/shared/types/inputVariant';
import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import type { Theme } from '@lib/styles/themes/type';

interface TextAreaProps {
    variant: InputVariant;
}

const getVariantValue = (variant: InputVariant, property: 'borderColor' | 'backgroundColor', theme: Theme): string => {
    const { textarea } = theme.deprecated.domains.shared;

    switch (variant) {
        case 'invalid':
            return textarea.invalid[property];
    }

    return textarea.default[property];
};

export const TextAreaElement = styled.textarea<TextAreaProps>`
    min-width: 200px;
    margin: 0;
    transition: box-shadow 0.2s ease-in-out;
    border: 1px solid;
    border-radius: 2px;
    border-color: ${({ theme, variant }): string => getVariantValue(variant, 'borderColor', theme)};
    background-color: ${({ theme, variant }): string => getVariantValue(variant, 'backgroundColor', theme)};
    font-size: ${SIZE.p3};

    &:hover {
        border-color: ${({ theme }): string => theme.deprecated.domains.shared.textarea.hover.borderColor};
    }

    &:focus {
        outline: none;
        box-shadow: ${({ theme }): string => theme.deprecated.domains.shared.textarea.default.boxShadow};
    }

    &:disabled {
        border-color: ${({ theme }): string => theme.deprecated.domains.shared.textarea.disabled.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.domains.shared.textarea.disabled.backgroundColor};
        color: ${({ theme }): string => theme.deprecated.domains.shared.textarea.disabled.color};
        font-style: normal;
        font-weight: ${WEIGHT.regular};
        cursor: not-allowed;

        &:hover {
            border-color: ${({ theme }): string => theme.deprecated.domains.shared.textarea.default.borderColor};
        }
    }
`;
