import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';

import { VALID_COUNTRY_CODE_REGEX, VALID_PHONE_NUMBER_REGEX } from './helpers';

export const createPhoneInputRegisterValidators = (
    t: Translator,
    validators: { required?: boolean },
): { required: string | false; validate: (value: string | undefined | null) => string | true } => ({
    required: validators.required ? t('frontend.shared.phone-input.error-required') : false,
    validate: (value): string | true => {
        // Full phone number is in format "COUNTRY_CODE PHONE_NUMBER", e.g. "+48 665 100 100"
        const [countryCode = '', phoneNumber] = (value ?? '').split(' ');

        if (!phoneNumber) {
            // Do not validate country phone until the number is entered
            return validators.required ? t('frontend.shared.phone-input.error-required') : true;
        }
        if (!VALID_COUNTRY_CODE_REGEX.test(countryCode)) {
            return t('frontend.shared.phone-input.error-country-code');
        }
        if (!VALID_PHONE_NUMBER_REGEX.test(phoneNumber)) {
            return t('frontend.shared.phone-input.error-format');
        }

        return true;
    },
});
