import type { JSX } from 'react';
import { useEffect, useRef, useState } from 'react';

import { Bullet } from './components/Bullet';
import {
    BULLET_CONTAINER_WIDTH,
    BULLET_VARIANT,
    MAX_ITEMS_NUMBER_WITH_DISABLED_SCROLLING,
    NUMBER_OF_VISIBLE_STANDARD_BULLETS,
} from './constants/bullet';
import { BulletsContainer, BulletWrapper, Wrapper } from './GalleryBullets.theme';
import { getBulletsAfterSlidingNext } from './helpers/getBulletsAfterSlidingNext';
import { getBulletsAfterSlidingPrevious } from './helpers/getBulletsAfterSlidingPrevious';
import { getBulletsWithFirstActive } from './helpers/getBulletsWithFirstActive';
import { getBulletsWithLastActive } from './helpers/getBulletsWithLastActive';
import { getBulletVariant } from './helpers/getBulletVariant';
import { getFirstVisibleBulletIndex } from './helpers/getFirstVisibleBulletIndex';
import { getNumberOfElementsToShow } from './helpers/getNumberOfElementsToShow';

interface Props {
    currentIndex: number;
    slidesCount: number;
}

export const GalleryBullets = ({ currentIndex, slidesCount }: Props): JSX.Element => {
    const [bullets, setBullets] = useState(getBulletsWithFirstActive(slidesCount));
    const containerWidth = getNumberOfElementsToShow(bullets) * BULLET_CONTAINER_WIDTH;

    const firstVisibleStandardBulletIndexRef = useRef(currentIndex);
    const firstBulletToShowIndex = getFirstVisibleBulletIndex(firstVisibleStandardBulletIndexRef);
    const translateX = -(firstBulletToShowIndex * BULLET_CONTAINER_WIDTH);

    const isBulletsScrollingEnabled = slidesCount > MAX_ITEMS_NUMBER_WITH_DISABLED_SCROLLING;

    useEffect(() => {
        if (!isBulletsScrollingEnabled) {
            return;
        }

        const newActiveBulletVariant = bullets[currentIndex];

        if (newActiveBulletVariant === BULLET_VARIANT.standard) {
            return;
        }

        if (!currentIndex) {
            firstVisibleStandardBulletIndexRef.current = 0;

            return setBullets(getBulletsWithFirstActive(slidesCount));
        }

        if (currentIndex === slidesCount - 1) {
            firstVisibleStandardBulletIndexRef.current = slidesCount - NUMBER_OF_VISIBLE_STANDARD_BULLETS;

            return setBullets(getBulletsWithLastActive(slidesCount));
        }

        if (firstVisibleStandardBulletIndexRef.current < currentIndex) {
            const newBullets = getBulletsAfterSlidingNext(
                bullets,
                firstVisibleStandardBulletIndexRef.current,
                currentIndex,
            );

            firstVisibleStandardBulletIndexRef.current++;

            return setBullets(newBullets);
        }

        if (currentIndex < firstVisibleStandardBulletIndexRef.current) {
            const newBullets = getBulletsAfterSlidingPrevious(bullets, currentIndex);

            firstVisibleStandardBulletIndexRef.current--;

            return setBullets(newBullets);
        }
    }, [bullets, currentIndex, isBulletsScrollingEnabled, slidesCount]);

    return (
        <BulletsContainer width={containerWidth}>
            <Wrapper translateX={translateX} data-testid="bullets-wrapper">
                {bullets.map((variant, index) => (
                    <BulletWrapper key={index}>
                        <Bullet
                            variant={getBulletVariant({
                                variant,
                                isActive: currentIndex === index,
                                isBulletsScrollingEnabled,
                            })}
                        />
                    </BulletWrapper>
                ))}
            </Wrapper>
        </BulletsContainer>
    );
};
