import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { SIZE } from '@lib/styles/partials/typography';

export const ErrorWrapper = styled.div`
    margin: 8px 0 0;
    overflow: hidden;
    color: ${({ theme }): string => theme.deprecated.domains.shared.fieldError.color};
    font-size: ${SIZE.p4};
    text-overflow: ellipsis;
`;

export const ErrorIcon = styled(Icon)`
    margin: 0 0.3em 0 0;
`;
