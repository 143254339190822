import { BULLET_VARIANT, MAX_ITEMS_NUMBER_WITH_DISABLED_SCROLLING, VISIBLE_ITEM_COUNT } from '../constants/bullet';
import type { BulletVariantValue } from '../types/bullet';

export const getNumberOfElementsToShow = (bullets: BulletVariantValue[]): number => {
    const firstBullet = bullets[0];
    const lastBullet = bullets[bullets.length - 1];

    if (bullets.length <= MAX_ITEMS_NUMBER_WITH_DISABLED_SCROLLING) {
        return bullets.length;
    }

    if (firstBullet === BULLET_VARIANT.medium || lastBullet === BULLET_VARIANT.medium) {
        return VISIBLE_ITEM_COUNT.oneSideExtended;
    }

    if (firstBullet === BULLET_VARIANT.small && lastBullet === BULLET_VARIANT.small) {
        return VISIBLE_ITEM_COUNT.bothSidesExtended;
    }

    return VISIBLE_ITEM_COUNT.basic;
};
