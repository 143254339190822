export const BULLET_VARIANT = {
    active: 'active',
    standard: 'standard',
    medium: 'medium',
    small: 'small',
} as const;

export const BULLET_TEST_ID = {
    active: 'active-bullet',
    standard: 'standard-bullet',
    medium: 'medium-bullet',
    small: 'small-bullet',
} as const;

export const VISIBLE_ITEM_COUNT = {
    basic: 5,
    oneSideExtended: 6,
    bothSidesExtended: 7,
} as const;

export const NUMBER_OF_VISIBLE_STANDARD_BULLETS = 3;

export const MAX_ITEMS_NUMBER_WITH_DISABLED_SCROLLING = 5;

export const BULLET_CONTAINER_WIDTH = 16;
